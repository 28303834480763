import styled, { createGlobalStyle,keyframes, css } from "styled-components";

/*
export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  html {
    height: 100%;
  }

  body {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    padding: 32px;
    margin: 0;
    padding: 0;
  }

  div#root {
    height: 100%;
  }
`;

export const Root = styled.main`
  flex-flow: column;
  align-items: center;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 50px 0 100px;
`;

export const Footer = styled.footer`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: silver;

  button {
    margin: 0 10px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
`;
*/
export const Preview = styled.img`
  width: 100%;
  height: auto;
`;


const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`;



export const Container = styled.div`
position: relative;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
width: 100%;
height: 65vh;
border-radius: 35px;
margin-top: 1vh;
background-color: black;
`;

export const Video = styled.video`
        
    pointer-events: none;
    position: relative;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    width: 100%;
    -webkit-border-radius: 35px;
`;
/**height: 50vh; */
export const Canvas = styled.canvas`
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;



export const Overlay = styled.div`
  position: absolute;
`;

export const Flash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  opacity: 0;

  ${({ flash }) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-out;
      `;
    }
  }}
`;

export const Button = styled.button`
  width: 75%;
  min-width: 100px;
  max-width: 100px;
  padding: 2px;
  background: silver;
  position: absolute;
  margin-top: 20%;
  
`;
export const Clicker = styled.button`
 background-color: white;
      color: black;
      border: 2px solid #008CBA;
`