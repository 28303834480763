import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App'

import './public/assets/css/style2.css'
import './public/assets/css/loader.css'
import './public/assets/css/load.css'
import zaio from './public/assets/fonts/Zaio.ttf'

import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CookiesProvider } from "react-cookie";
ReactDOM.render(
  <React.StrictMode >
      <CookiesProvider> 
    < App/>
     </CookiesProvider> 
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
