import { useState, useEffect } from "react";
import { detect } from 'detect-browser';
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

const getAspectRatio = (width, height)  => {
  const isLandscape = height <= width;
  const ratio = isLandscape ? width / height : height / width;
  return ratio;
};

export function useUserMedia(requestedMedia) {
  const [cookies, setCookie] = useCookies(['available_cameras', 'selected_camera']);
  const [mediaStream, setMediaStream] = useState(null);
  const [availableCameras, setAvailableCameras] = useState([]);
  const [backCameraCount, setBackCameraCount] = useState(0);
  const [selectedCameraId, setSelectedCameraId] = useState("default");
  const [videoCapabilities, setVideoCapabilities] = useState([]);
  
  const browserDt = detect();
  let history = useHistory();
  //const ratioVal = getAspectRatio(window.screen.availWidth,window.screen.availHeight).toFixed(9);
  //alert(ratioVal);
//alert(window.screen.availWidth+"**"+window.screen.availHeight+"==="+window.innerHeight+"**"+window.innerWidth);
const ratioVal = 4/3;
const scrHeight = window.screen.availHeight / 0.75;
const scrWidth = window.screen.availWidth;
const screenWidthRes =  Math.floor(scrWidth * window.devicePixelRatio);
const screenHeightRes = Math.floor(scrHeight * window.devicePixelRatio);
//const screenWidthRes =  window.screen.availWidth * window.devicePixelRatio ;
//const screenHeightRes = window.screen.availHeight * window.devicePixelRatio ;
  
   
  useEffect(() => {  
    var constraints;

    async function getListOfVideoInputs()  {
      console.log("getListOfVideoInputs");
      // Get the details of audio and video output of the device
      const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
  
      //Filter video outputs (for devices with multiple cameras)
      return enumerateDevices.filter((device) => device.kind === "videoinput");
    };

      
    async function getBackCameraCount(camerasInfo) {
      console.log("getBackCameraCount");
      //console.log("Dev lenght=="+camerasInfo.length);
      //console.log(camerasInfo.length);
      var count = 0;
      for(var j=0; j !== camerasInfo.length; j++){
        //console.log(camerasInfo[j].label);
        if(JSON.stringify(camerasInfo[j].label).search("back") >= 0 || JSON.stringify(camerasInfo[j].label).search("HD") >= 0) {              
             count++;
        }    
      }      
      return count;
    }

    async function deviceInfo(deviceInfos) {
      console.log("deviceInfo == "+deviceInfos);
      for (var i = 0; i !== deviceInfos.length; i++) {
        var deviceInfo = deviceInfos[i];
        
        if(deviceInfo.deviceId !== "undefined" ){
          if (deviceInfo.kind === 'videoinput') {    
              if((JSON.stringify(deviceInfo.label).search('back') >= 0) || (JSON.stringify(deviceInfo.label).search('HD') >= 0)) {
                
              var conSt = {
                  audio: false,
                  video: {
                    //deviceId: deviceInfo.deviceId,
                    deviceId: { exact: deviceInfo.deviceId },
                    width: {
                      ideal: screenWidthRes
                    },
                    height: {
                      ideal: screenHeightRes
                    },
                    facingMode: "environment",
                    aspectRatio: { exact: ratioVal }
                  } 
              } 
              //console.log(conSt);
              
              const stream = await navigator.mediaDevices.getUserMedia(conSt);
              
              stream.getTracks().forEach(track => track.stop());  

                stream.getVideoTracks().forEach( track => {               
                      var arr = track.getCapabilities(); 
                      //console.log("Cap arr="+JSON.stringify(arr));
                      //alert("Cap arr="+JSON.stringify(arr));
                        if(typeof arr !== 'undefined') {
                          videoCapabilities.push(arr);
                        }
                    })
              }    
              }    
        }else{
          console.log("undefined");
        }  
        
    
      }
    
    }

    async function getExpectedDeviceId() {   
      console.log("getExpectedDeviceId");  
        let callCapabilities = videoCapabilities.map((o)=>{     
            return{
              iso:o.iso,
              torch:o.torch,
              d_id:o.deviceId,
              label:o.label
            }     
        })
        //alert("Foreach started");  
        var selDeviceId = selectedCameraId;
      callCapabilities.forEach(element => { 
             if(element.torch === true) {     
               let selected_camera_id = element.d_id;
               //alert("Cap:Torch Camera ID:"+selected_camera_id);
               selDeviceId =  selected_camera_id;
               //setSelectedCameraId(selected_camera_id);   
             }
             else{
               selDeviceId =  element.d_id;
               //alert("Else Cap: Camera ID:"+selDeviceId);
              // setSelectedCameraId(element.d_id);
              //setSelectedCameraId("default"); 
             }     
       });    
       //alert("ExpectedDevice="+selDeviceId);
       return selDeviceId;
     }


     async function getSingleBackCameraDeviceId(deviceInfos) {
      console.log("getSingleBackCameraDeviceId ");
      
      for (var i = 0; i !== deviceInfos.length; i++) {
        var deviceInfo = deviceInfos[i];        
        if(deviceInfo.deviceId !== "undefined" ){   
              if((JSON.stringify(deviceInfo.label).search('back') >= 0) || (JSON.stringify(deviceInfo.label).search('HD') >= 0)) {
               return deviceInfo.deviceId            
              }   
        }
      }
      return selectedCameraId;   
    }

    async function getSuitableDeviceId(videoInputs, cameraCount) {
      var selectDeviceId = selectedCameraId;
      //alert("SuitableDevice Back Camera Count="+cameraCount);
      if (parseInt(cameraCount) === 1 || parseInt(cameraCount) === 0) { 
        //selectDeviceId = "default"; 
        selectDeviceId = await getSingleBackCameraDeviceId(videoInputs);     
      } else if ( parseInt(cameraCount) === 2) {
        //alert("Entered in camera area");
        var devInfoValues = await deviceInfo(videoInputs);    
        //now determine device id using torch == true
        selectDeviceId = await getExpectedDeviceId(videoInputs);
      }
      return selectDeviceId;
    }  

    async function loadVideo(constraints)
    {
      const stream = await navigator.mediaDevices.getUserMedia(
        constraints
      );
      setMediaStream(stream);
    }

    
    
    async function enableVideoStream() {
      try {

        if (browserDt.os ==="iOS") {
          console.log("In Ios");
          const videoInputs =   await getListOfVideoInputs();
          setCookie("available_cameras", videoInputs, { path: "/"});
          setCookie("selected_camera", "default", { path: "/"});

          const scrHeight = window.innerHeight / 1.6;
          const scrWidth = window.innerWidth + 100;
          const screenWidthRes =  Math.floor(scrWidth * window.devicePixelRatio);
          const screenHeightRes = Math.floor(scrHeight * window.devicePixelRatio);
          
            constraints = {
              audio: false,
              video: {
                resizeMode: 'none', 
                facingMode: "environment",
                width: {
                  ideal: screenWidthRes
                },
                height: {
                  ideal: screenHeightRes
                 }
              }
            };
            await loadVideo(constraints);
        } else {
          // In android
          let permissionState = "";
          await navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then(stream => {   
                permissionState = 'granted'; 
                stream.getTracks().forEach(t=>t.stop());            
            }).catch(error =>{
                      navigator.permissions.query({ name: "camera" }).then(res => {
                        permissionState = res.state;
                        console.log(" Permission : " + permissionState)
                        if(res.state == "denied"){
                            console.log("User denied to access camera"); 
                            alert("We need camera permission to proceed this application");
                            history.push('/');           
                        }
                  });  
                });

          const videoInputs =   await getListOfVideoInputs();
          availableCameras.push(videoInputs);
          setCookie("available_cameras", videoInputs, { path: "/"});
          //alert('Available Video Inputs: ' + JSON.stringify(videoInputs));
          const bCameraCount =  await getBackCameraCount(videoInputs);
         //setBackCameraCount(bCameraCount);
         //alert('Back Camera count : ' + bCameraCount);
         var selectedCameraId = await getSuitableDeviceId(videoInputs, bCameraCount).then(async (res)=>{
          //console.log("Inside Device Id = "+res);
          selectedCameraId = res;
          setCookie("selected_camera", selectedCameraId, { path: "/"});
          console.log("Device Id = "+selectedCameraId);
            constraints = {
              audio: false,
              video: {
                resizeMode: 'none', 
                facingMode: "environment",          
                //deviceId:selectedCameraId,
                deviceId: (selectedCameraId!=="default") ? {exact: selectedCameraId} : selectedCameraId,
                width: {
                  ideal: screenWidthRes
                },
                height: {
                  ideal: screenHeightRes
                },
                aspectRatio: { exact: ratioVal },
                advanced: [
                  {'width': {'min': 1280}}
                ] 
              }
            };  
            console.log(JSON.stringify(constraints));
            //alert('Constraints : ' + JSON.stringify(constraints));
            const stream = await navigator.mediaDevices.getUserMedia(
              constraints
            );
            setMediaStream(stream);
         });

        }     
        
        
        

      } catch (err) {
        console.log(err);
      }
    }

    if (!mediaStream) {
      enableVideoStream();
    } else {
      return function cleanup() {
        mediaStream.getTracks().forEach(track => {
          track.stop();
        });
      };
    }
  }, [mediaStream, requestedMedia]);

  return mediaStream;
}
