import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

export function useToken(tokenURL) {
  const [jwtToken, SetJwtToken] = useState(null);  
  const [cookies, setCookie] = useCookies(['accessToken', 'deviceUsername', 'deviceUserId', 'clientIP']);
   
  useEffect(() => {      
    var accessToken = cookies.accessToken;
    
    async function generateToken(accessToken) {
      try {

        if(accessToken === "" || typeof accessToken === 'undefined'){
          const requestOptions = {
            method: "POST",        
            headers: {
              "Content-Type": "text/plain"
            },
            body: JSON.stringify({
              en_date_time: new Date().toISOString(),
              user_id: cookies.deviceUserId,
              User_name:cookies.deviceUsername,
              ip_address:cookies.clientIP
            }),
          };  
          
          fetch(tokenURL, requestOptions)
            .then(async (response) => {
              const data = await response.json();

              if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
              }
      
              const  genToken  = data.jwt_header.actual_access_token;
              var date = new Date();
              var minutes = 2.5;
              date.setTime(date.getTime() + (minutes * 60 * 1000)); 
              
              setCookie("accessToken", genToken, { path: "/", expires: date });
              SetJwtToken(genToken);
             })
            .catch((error) => {
              console.error("Error occurred during create token API call => ", error);
              return Promise.reject(error);
            });          
        }    

      } catch (err) {
        console.error("Error occurred while token generation => ",err);
      }
    }

    if (accessToken === "" || typeof accessToken === 'undefined') {
      generateToken(accessToken);
    } else {
      SetJwtToken (cookies.accessToken);
    }
  }, [jwtToken]);

  return jwtToken;
}
