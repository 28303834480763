import React, { useRef, useState, useEffect } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { useCookies } from "react-cookie";
import { detect } from "detect-browser";
import Measure from "react-measure";
import intro from "../public/assets/img/20Intro.gif";
import { useUserMedia } from "../hooks/use-user-media";
import { useToken } from "../hooks/use-token";
import * as tf from "@tensorflow/tfjs";
import greenTick from "../public/assets/img/green_tick Icon.svg";
import redTick from "../public/assets/img/red_cross Icon.svg";
import DecStatData from "../public/assets/model/dec_status.json";
import {
  Video,
  Canvas,
  Wrapper,
  Container,
  Flash,
  Overlay,
  Preview,
  Button,
} from "../styles";

const loaderAdjust = {
  position: "relative",
  marginTop: "-12px",
};

const ROOT_API = process.env.REACT_APP_API_URL;

const productionEnabled = false;
const proxyurl = "";

var { tokenURL, decodeURL, userInfoURL, prodStatURL, saveURL, userInfoStat } = "";

if (productionEnabled) {
  tokenURL = proxyurl + ROOT_API + "/api/create_jwt_token";
  decodeURL = ROOT_API + "/api/decode";
  userInfoURL = ROOT_API + "/api/user-info";
  prodStatURL = ROOT_API + "/api/stat";
  saveURL = ROOT_API + "/api/save_img";

} else {
  tokenURL = proxyurl + ROOT_API + "/api/create_jwt_token";
  decodeURL = ROOT_API + "/api/decode";
  userInfoURL = ROOT_API + "/api/user-info";
  prodStatURL = ROOT_API + "/api/stat";
  userInfoStat = ROOT_API + "/api/customer_info";
  saveURL = ROOT_API + "/api/save_img";
}

let newImage;
let testPage = false;
let checkPage = false;
let openNativeCamera = false;
let safariVersion = false;
let notSafari = false;
const decode_api_error_dec_stat = 4.12 // When some error occu4red in decode API\
const bad_photo_quality_dec_stat = 4.11 // Default dec stat for bad quality image
const bad_photo_quality_model_index = 4 // Model index for bad photo quality class

const MainPart = () => {

  let history = useHistory();
  const browserDt = detect();
  if (browserDt.name !== "chrome" && browserDt.name !== "ios")
    openNativeCamera = true;

  if (browserDt.os === "iOS") {
    if (browserDt.name === "safari" || browserDt.name === "ios") {
      safariVersion = true;
    } else {
      notSafari = true;
    }
  }

  // wrapped json web token
  let [jwtToken, SetJwtToken] = useState(useToken(tokenURL));  
  let [cookies, setCookie, removeCookie] = useCookies(['accessToken', 'deviceUsername', 'deviceUserId', 'clientIP', "latData,lngData"]);
  let [userToken, SetUserToken] = useState();

  // fetch Current Time
  let currentDateTime = new Date().toISOString();

  //state used to show Gif file
  let [scan, setScan] = useState(false);
  let [checkLocation, setCheckLocation] = useState(false);
  let [playGif, setPlayGif] = useState(true);

  // state set array of respond message from server
  let [msgData, setMsgData] = useState([]);

  // state determine show component either success or failed respect to the respond
  let [scanV2, setScanV2] = useState(false);
  let [scanV21, setScanV21] = useState(false); // For Everest and Promo artworks
  let [scanV3, setScanV3] = useState(false);
  let [scanV4, setScanV4] = useState(false);
  let [resDelay, setResDelay] = useState(false);
  const [checkcamera, setCheckCamera] = useState("");

  const [loading, setloading] = useState(false);
  
  //form data
  let [fullname, setName] = useState(null);
  let [user_number, setNumber] = useState(null);
  let [formvisible, setFormvisible] = useState(false);

  // image reference functional hook
  const imageRef = useRef(null);
  const [model, setModel] = useState(null);
  const [isModelLoading, setIsModelLoading] = useState(false);

  // wrapped camera details
  const [camDetails, setCamDetails] = useState("");

  const [deviceinfo, setDeviceInfo] = useState("");
  const [isCameraOpen, setIsCameraOpen] = useState(false);

  //details about taken image
  const [cardImage, setCardImage] = useState();

  // capture image and recorded video refHook
  const canvasRef = useRef();
  const videoRef = useRef();
  const [userSatId, setUserStatId] = useState();

  //state return taken image
  const [image, setImage] = useState(null);
  let [showImage, setShowImage] = useState(null);

  //actual camera size respect to each device
  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [parentContainer, setParentContainer] = useState({
    width: 0,
    height: 0,
  });

  // state respond for camera recorded WebRpc Video
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [isFlashing, setIsFlashing] = useState(false);

  let [filename, setFilename] = useState(null);

  let [msg, setMsg] = useState("");
  let [modelIndex, setModelIndex] = useState(null);
  let [modelPredictPercentage, setModelPredictPercentage] = useState("");
  let [modelPredict, setModelPredict] = useState("");
  const [prevTS, SetPrevTS] = useState(0);
  let [postTS, SetPostTS] = useState(0);
  let [totalTime, setTotalTime] = useState(0);
  let [ready, setReady] = useState(false);
  let [decodeImage, setDecodeImage] = useState(false);
  let latData = cookies.latitude;
  let lngData = cookies.longitude;

  // FrontEnd ML Model prediction Time calculation
  let front_ml_predict_class = ""
  let front_ml_predict_percentage = ""
  let front_ml_predict_start_time = new Date().getTime();
  let front_ml_predict_end_time = new Date().getTime();
  let front_ml_predict_time_taken = 0.0;

  function parseQryStr() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var paramVal = url.searchParams.get("reScan");
    return paramVal;
  }

  let scanAgain = parseQryStr();
  if (scanAgain == 1) {
    playGif = false;
  }

  // use media is main function for WebRPC
  const mediaStream = useUserMedia();
  let lowQuality = true;

  //let media_recorder;
  const [mediaRecorder, setMediaRecorder] = useState();
  let blobs_recorded = [];
  const [isRecording, setIsRecording] = useState(false);

  // gif play time set to 6 sec, after the 2 sec of intro
  useEffect(() => {
    if (latData == 0 || lngData == 0) {
      setCheckLocation(false);
    } else {
      setCheckLocation(true);
    }
    loadModel();
    setTimeout(() => {
      setPlayGif(false);
    }, 5000);
  }, [playGif]);

  useEffect(() => {
    if (playGif == true) {
      setTimeout(() => {
        setReady(true);
      }, 6200);
    } else {
      setTimeout(() => {
        setReady(true);
      }, 5200);
    }
  });

  if (parseFloat(msgData.msgType) == 2 || parseFloat(msgData.msgType) == 4) {
    lowQuality = true;
  }
  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
  }

  const loadModel = async () => {
    setIsModelLoading(true);
    const modelURL = "assets/model/Harpic_quality_V11_2024_03_05_11/TFJS/model.json";
    try {
      var model = await tf.loadGraphModel(modelURL);
      setModel(model);
      setIsModelLoading(false);
    } catch (error) {
      console.log("Error occurred while loading the ML model, Error =>", error);
      setIsModelLoading(false);
    }
  };

  // handling capture image processing
  function handleUploadImage(event) {
    if (checkLocation == false) {
      alert(
        " Location permission denied. Please grant location permission from site settings"
      );
      window.location.reload(false);
    } else {

      const imgFile = event.target.files[0];
      if (imgFile) {
        var fr = new FileReader();

        fr.onloadend = () => {
          var image = fr.result;
          setImage(image);
          newImage = image;
          resizeImg(image, 300);
        };

        fr.readAsDataURL(imgFile);
      }
    }
  }

  // WebRpc respond for working with native camera
  function startRecording() {
    let media_recorder = new MediaRecorder(mediaStream, {
      mimeType: "video/webm",
    });
    setMediaRecorder(media_recorder);
    // event : new recorded video blob available
    media_recorder.addEventListener("dataavailable", function (e) {
      blobs_recorded.push(e.data);
    });

    // event : recording stopped & all blobs sent
    media_recorder.addEventListener("stop", function () {
      // create local object URL from the recorded video blobs
      let video_local = URL.createObjectURL(
        new Blob(blobs_recorded, { type: "video/webm" })
      );

      const uniqueFilename =
        Math.floor(Math.random() * 1000000000000 + 10) + ".webm";
      const downloadLink = document.createElement("a");
      downloadLink.href = video_local;

      downloadLink.download = uniqueFilename;
      downloadLink.click();
    });

    // start recording with each recorded blob having 1 second video
    media_recorder.start();
    setIsRecording(true);
  }

  function stopRecording() {
    mediaRecorder.stop();
    setIsRecording(false);
  }

  // responsive camera size for each device respectively
  function handleResize(contentRect) {
    const height = document.getElementById("parentDiv").clientHeight;
    const width = document.getElementById("parentDiv").clientWidth;
    const vidStyleData = videoRef.current.getBoundingClientRect();

    setContainer({
      width: width,
      height: height,
      vWidth: vidStyleData.width,
      vHeight: vidStyleData.height,
      vLeft: vidStyleData.left,
      vTop: vidStyleData.top,
    });
  }

  function handleClick() {
    try {
      setCookie("scanAgain", 1, {
        path: "/",
        expires: 0,
        secure: true,
        sameSite: "lax",
      });
      // window.location.reload(true)
      var url = window.location.href;
      if (url.indexOf("?") > -1) {
      } else {
        url += "?reScan=1";
      }
      window.location.href = url;
    } catch (err) {
      console.log("Error occurred while handling the click button, Error => ", err);
    }
  }

  function handleCanPlay() {
    //calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    setIsVideoPlaying(true);
    videoRef.current.play();
  }

  // handling capture image and sent to server
  // function alertHandler(){
  //   alert(" Location permission denied. Please grant location permission from site settings")
  // }
  function handleCapture() {
    if (checkLocation == false) {
      alert(
        "Location permission denied. Please grant location permission from site settings"
      );
      window.location.reload(false);
    } else {
      videoRef.current.pause();
      // New
      var canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      newImage = canvas.toDataURL("image/jpeg");
      resizeImg(newImage, 300);
      // image state get stateFull with taken image

      const context = canvasRef.current.getContext("2d");
      var vRatio = (container.height / container.vHeight) * container.vWidth;
      //var hRatio = (container.width / container.vWidth) * container.vHeight;
      //context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      context.drawImage(
        videoRef.current,
        container.vLeft,
        0,
        vRatio,
        container.vHeight
      );

      setIsCanvasEmpty(false);
      setIsFlashing(true);
    }
  }

  const generateToken = (refresh_token = false) => {

    if (refresh_token == true){
      return new Promise((resolve, reject) => {
        let custom_metadata = {
          "source_url": window.location.href
        }
        const requestOptions = {
          method: "POST",        
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            en_date_time: new Date().toISOString(),
            user_id: cookies.deviceUserId,
            User_name:cookies.deviceUsername,
            custom_metadata: custom_metadata
          }),
        };  

        fetch(tokenURL, requestOptions)
        .then(async (response) => {
          const data = await response.json();

          if (!response.ok) {
            const error = (data && data.message) || response.status;
            reject(error);
          }
          const  genToken  = data.jwt_header.actual_access_token;
          var date = new Date();
          var minutes = 2.5;

          date.setTime(date.getTime() + (minutes * 60 * 1000)); 
          setCookie("accessToken", genToken, { path: "/", expires: date });
          SetJwtToken(genToken);
          resolve(genToken)
         })

        .catch((error) => {
          console.error("There was an error occured while refreshing the token => ", error);
          reject(error);
        });
      });

    }else{

      return new Promise((resolve, reject) => {
        var accessToken = cookies.accessToken;
        
        if (accessToken !== "" && typeof accessToken !== "undefined") {
          resolve(accessToken);
        } else if (
          jwtToken !== null &&
          jwtToken !== "" &&
          typeof jwtToken !== "undefined"
        ) {
          console.log("Token => ", jwtToken);
          resolve(jwtToken);
        } else {
          reject("Token Missing");
        }
      });
    }
  };

  function onClear() {
    setCardImage(undefined);
  }

  function handleClear() {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsCanvasEmpty(true);
    onClear();
  }

  const resizeImg = async (image, size) => {
    setloading(true);

    const canvas0 = document.createElement("canvas");
    var ctx = canvas0.getContext("2d");
    canvas0.width = size;
    canvas0.height = size;
    var imgData = ctx.createImageData(size, size);
    for (let i = 0; i < imgData.data.length; i += 4) {
      imgData.data[i + 0] = 0;
      imgData.data[i + 1] = 0;
      imgData.data[i + 2] = 0;
      imgData.data[i + 3] = 255;
    }

    ctx.putImageData(imgData, 0, 0);
    const image1 = new Image();
    image1.src = image;
    image1.onload = () => {
      var w = image1.width;
      var h = image1.height;
      var minw = size / w;
      var minh = size / h;
      var scale = Math.min(...[minw, minh]);
      var nw = Math.trunc(w * scale);
      var nh = Math.trunc(h * scale);
      var top = 0;
      var left = 0;
      if (nw < size || nh < size) {
        top = Math.trunc((size - nh) / 2);
        left = Math.trunc((size - nw) / 2);
      }

      ctx.drawImage(image1, left, top, nw, nh);
      //Draw here
      var resizedImage = canvas0.toDataURL();
      canvas0.remove();
      predictVideo(resizedImage, image);
    };
  };

  if (mediaStream) {
    document.getElementById("cameraWrapper").style.backgroundColor = "white";
  }

  const getListOfVideoInputs = async () => {
    // Get the details of audio and video output of the device
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();

    //Filter video outputs (for devices with multiple cameras)
    return enumerateDevices.filter((device) => device.kind === "videoinput");
  };

  const getImage = async (imageInBase64, predictedClass, refresh_token = false, gerImageFuncCallCounter = 1) => {
    generateToken(refresh_token)
      .then((res) => {
        let token = res;

        if (cookies.mobile_type === " " || cookies.mobile_type === undefined) {
          var mobile_type = "www";
        } else {
          mobile_type = cookies.mobile_type;
        }
        console.log("mobile_type => ", mobile_type);
        let custom_metadata = {
          "source_url": window.location.href
        }
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            imagedata: imageInBase64,
            en_date_time: currentDateTime,
            geo_loc_lat: cookies.latitude,
            geo_loc_lng: cookies.longitude,
            User_name: cookies.deviceUsername,
            session_id: res,
            mob_type: mobile_type,
            title: predictedClass,
            front_ml_predict_class: front_ml_predict_class,
            front_ml_predict_percentage: front_ml_predict_percentage,
            front_ml_predict_timetaken: front_ml_predict_time_taken,
            custom_metadata: custom_metadata
          }),
        };

        fetch(proxyurl + decodeURL, requestOptions)
          .then(async (response) => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }

            const messageType = data.body.decode_status.message;
            const v2_decoded = data.body.decode_status.v2_decoded;
            const content = data.body.content.Heading;

            setFilename(data.body.decode_status.filename);
            SetUserToken(token);
            getProduct(token, data, messageType);
            getUserInfo(token);
            setloading(true);
            //scan value return true if process complete with Backend
            setScan(true);

            if (parseInt(v2_decoded) == 2) {
              // setScanV21(true); // Everest original artwork where to show Congrats page without green tick
              setScanV2(true); // Showing Congrats with green tick for Everest original artwork also
            } else if (parseInt(messageType) == 0) {
              setScanV2(true);
            } else if (parseInt(messageType) == 3) {
              // Potential Fake case
              setScanV4(true);

            } else {
              setScanV3(true);
              setMsgData({ message: content, msgType: messageType });

            }

            // Stop all streams after get result
            mediaStream.getTracks().forEach((track) => {
              track.stop();
            });
          })
          .catch((error) => {
            console.error("There was an error occurred during decode API call => ", error);
            if (gerImageFuncCallCounter <= 2){
              getImage(imageInBase64, predictedClass, true, gerImageFuncCallCounter + 1)
            }else{
              setDecodeImage(false)
              setModelIndex(bad_photo_quality_model_index);
              saveRejectImages( imageInBase64, predictedClass, front_ml_predict_percentage, decode_api_error_dec_stat, front_ml_predict_time_taken )
            }
          });
      })
      .catch((error) => {
        if ("Token Missing" in error.toLowerCase()){
          console.log("Token missing error while deocding the image => ", error)
          if (gerImageFuncCallCounter <= 2){
            getImage(imageInBase64, predictedClass, true, gerImageFuncCallCounter + 1)
          }else{
            setDecodeImage(false)
            setModelIndex(bad_photo_quality_model_index);
            saveRejectImages( imageInBase64, predictedClass, front_ml_predict_percentage, decode_api_error_dec_stat, front_ml_predict_time_taken )
          }
        }else{
          console.error("There was an error occrred while decoding => ", error);
          removeCookie("accessToken")
          window.location.reload(true);
        }
      });
  };

  const getUserInfo = (token) => {
    let custom_metadata = {
      "source_url": window.location.href
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        User_name: cookies.deviceUsername,
        en_date_time: currentDateTime,
        Device_OS_Number: "unknown",
        Browser_Details: JSON.stringify(browserDt),
        Cam1: JSON.stringify(cookies.available_cameras),
        Preferred_Camera: cookies.selected_camera,
        session_id: token,
        front_ml_predict_class: front_ml_predict_class,
        front_ml_predict_percentage: front_ml_predict_percentage,
        front_ml_predict_timetaken: front_ml_predict_time_taken,
        custom_metadata: custom_metadata
      }),
    };

    fetch(proxyurl + userInfoURL, requestOptions)
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        console.error("Error occurred while calling the 'user info' API", error);
      });
  };

  const saveRejectImages = (
    newImage,
    predictedClass,
    predictPercentage,
    dec_data,
    front_ml_predict_time_taken,
    refresh_token = false,
    saveRejectFuncCallCounter = 1
  ) => {
    generateToken(refresh_token).then((res) => {
      let custom_metadata = {
        "source_url": window.location.href
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          imagedata: newImage,
          session_id: res,
          mt: front_ml_predict_time_taken,
          app_user_name: cookies.deviceUsername,
          app_user_id: cookies.deviceUserId,
          geo_loc: cookies.latitude + "," + cookies.longitude,
          pb: predictPercentage,
          en_date_time: currentDateTime,
          geo_loc_lat: cookies.latitude,
          geo_loc_lng: cookies.longitude,
          User_name: cookies.deviceUsername,
          title: predictedClass,
          dec_stat: dec_data,
          front_ml_predict_class: front_ml_predict_class,
          front_ml_predict_percentage: front_ml_predict_percentage,
          front_ml_predict_timetaken: front_ml_predict_time_taken,
          custom_metadata: custom_metadata
        }),
      };

      fetch(proxyurl + saveURL, requestOptions)
        .then(async (response) => {
          const data = await response.json();
          setloading(false);
          setScan(true);
        })
        .catch((error) => {
            console.error("Error occurred while save image API calling => ", error);  
            if (saveRejectFuncCallCounter <= 2){
              saveRejectImages( newImage, predictedClass, predictPercentage, dec_data, front_ml_predict_time_taken, true, saveRejectFuncCallCounter + 1 )
            }else{
              setloading(false);
              setScan(true);
            }
          });
    });
  };

  const predictVideo = async (image, originalImage) => {
    setloading(true);

    videoRef.current.pause();
    var imageFile;

    var img = document.createElement("img");
    img.src = image;
    img.remove();
    
    tf.engine().startScope();
    setTimeout(() => {
      
      front_ml_predict_start_time = new Date().getTime() // Starting ML Prediction process to start
      var tensor = tf.browser.fromPixels(img);
      var offset = tf.scalar(255.0);
      var tensor1 = tensor.div(offset).toFloat().expandDims();
      var predictions = model.predict(tensor1).data();

      predictions.then((data) => {
        var IMAGENET_CLASSES = [
          "Harpic_Back",
          "Harpic_Front_Everest",
          "No_Product",
          "legacy_harpic_new_thicker",
          "legacy_harpic_paani"
        ];

        var results = Array.from(data);
        var index = results.indexOf(Math.max.apply(Math, results));
        setModelIndex(index);
        
        front_ml_predict_end_time = new Date().getTime(); // Starting ML Prediction process to Stop
        front_ml_predict_time_taken = ((front_ml_predict_end_time - front_ml_predict_start_time)/1000).toFixed(2);
        
        var predictedClass = IMAGENET_CLASSES[index];
        var dec_data = DecStatData[predictedClass];
        front_ml_predict_class = predictedClass
        console.log("Front-end model predicted classname => " + predictedClass);

        var predictedPercent = results[index].toFixed(4) * 100;
        front_ml_predict_percentage = predictedPercent
        console.log("Front-end model predicted percentage => " + predictedPercent);

        setModelPredictPercentage(predictedPercent.toFixed(2));
        setModelPredict(predictedClass);

        checkPage = true;
        const now2 = parseInt(Math.round(new Date().getTime() / 1000));
        SetPostTS(now2);

        setTotalTime(parseFloat(front_ml_predict_time_taken));
        console.log("Front-end model predict time taken => " + front_ml_predict_time_taken);

        if (index === 1 || index === 3 || index === 4) {
          if (predictedPercent >= 60) {
            setResDelay(true);
            setDecodeImage(true)
            getImage(originalImage, predictedClass);
          } else {
            saveRejectImages(
              originalImage,
              predictedClass,
              predictedPercent,
              bad_photo_quality_dec_stat,
              front_ml_predict_time_taken
            );
            setModelIndex(bad_photo_quality_model_index);
          }
        } else {
          saveRejectImages(
            originalImage,
            predictedClass,
            predictedPercent,
            dec_data,
            front_ml_predict_time_taken
          );
        }
      });
    }, 2000);
  };

  // Prodservice-prod-stat  -> call this api function
  const getProduct = (token, data, deccode_stat) => {
    let custom_metadata = {
      "source_url": window.location.href
    }
    let prodStatParam = {
      product: {
        geo_loc_lat: cookies.latitude,
        geo_loc_lan: cookies.longitude,
        geo_loc: cookies.latitude + "," + cookies.longitude,
        app_user_name: cookies.deviceUsername,
        app_user_id: cookies.deviceUserId,
        man_id: 0,
        en_feedback_type: 0,
        en_date: currentDateTime,
        dec_stat: deccode_stat,
        res: data,
        session_id: token,
        front_ml_predict_class: front_ml_predict_class,
        front_ml_predict_percentage: front_ml_predict_percentage,
        front_ml_predict_timetaken: front_ml_predict_time_taken,
        custom_metadata: custom_metadata
      }
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(prodStatParam),
    };

    fetch(proxyurl + prodStatURL, requestOptions)
      .then(async (response) => {
        const data = await response.json();
        setUserStatId(data.stat_id);
      })
      .catch((error) => {
        console.error("Error occurred while calling 'stat' API, Error => ", error);
      });
  };

  const DivContainer = {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  };

  const introDiv = {
    paddingTop: "110px",
    width: "355px",
    height: "450px",
    marginBottom: "50px",
  };
  
  return (
    <div style={{ width: "100%" }}>
      {/* Component only play gif till the page is loaded */}
      <div
        style={{ display: "flex", justifyContent: "center" }}
        hidden={!playGif}
      >
        <img src={intro} style={introDiv} alt="loading..." />
      </div>
      <div hidden={playGif || scan}>
        <link rel="stylesheet" href="assets/css/style1.css?v=1.1" />
        <div className="d-flex flex-column justify-content-between ">
          <div className="container">
            <div className="d-flex flex-column  justify-content-between">
              <div className="top-logo">
                <div className="logoDIv text-left">
                  <img
                    alt=""
                    style={{ display: !openNativeCamera ? "none" : "" }}
                  />
                </div>
              </div>
              <div className="bottom-section">
                <div className="row justify-content-sm-center">
                  <div className="col-sm-11 col-xs-11 nopadding">
                    {openNativeCamera && (
                      <section className="section-01">
                        <div className="browser-container">
                          <div className="dev-wrapper">
                            <div className="webcam-wrapper ">
                              {notSafari ? (
                                <h2
                                  className="text-center mb-3"
                                  style={{
                                    fontFamily: "Helvetica Neue",
                                    display: loading ? "none" : "",
                                  }}
                                >
                                  This site is optimized for Safari.
                                </h2>
                              ) : !safariVersion ? (
                                <h2
                                  className="text-center mb-3"
                                  style={{
                                    fontFamily: "Helvetica Neue",
                                    display: loading ? "none" : "",
                                  }}
                                >
                                  This site is optimized for Chrome.
                                </h2>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          </div>

                          <div
                            className="button-container mt-2"
                            style={{ pointerEvents: loading ? "none" : "" }}
                          >
                            <label
                              for="file-input"
                              style={{
                                fontFamily: "Helvetica Neue",
                                display: loading ? "none" : "",
                              }}
                              class="native-cam text-center py-3"
                            >
                              {!safariVersion ? (
                                <div>Click to Proceed</div>
                              ) : (
                                <div>Click to Open Camera</div>
                              )}
                            </label>
                            <input
                              className=""
                              id="file-input"
                              type="file"
                              accept="image/*"
                              capture="camera"
                              onChange={handleUploadImage}
                              hidden
                            />
                            <div className="container">
                              <div className="section-webcam" style={Ticker}>
                                <div
                                  style={{
                                    borderRadius: "35px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={newImage}
                                    style={{
                                      height: "39vh",
                                      width: "100%",
                                      objectFit: "contain",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {loading && (
                            <div id="hloader-container">
                              <img
                                src="/assets/img/Hourglass01.gif"
                                alt="Processing...."
                              />
                            </div>
                          )}
                        </div>

                        <div>
                          <div className="browser-container">
                            <div className="dev-wrapper">
                              <div className="webcam-wrapper "></div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}

                    <section
                      className="section-webcam "
                      style={{
                        paddingBottom: "20px",
                        display: openNativeCamera ? "none" : "",
                      }}
                    >
                      <Measure bounds onResize={handleResize}>
                        {({ measureRef }) => (
                          <Container ref={measureRef} id="cameraWrapper">
                            {isVideoPlaying && (
                              <div id="hloader-txt">Opening Camera ...</div>
                            )}

                            <Video
                              ref={videoRef}
                              hidden={!isVideoPlaying}
                              onCanPlay={handleCanPlay}
                              autoPlay={false}
                              playsInline
                              muted
                            />

                            <Overlay hidden={!isVideoPlaying} />

                            <Canvas
                              ref={canvasRef}
                              width={container.vWidth}
                              height={container.height}
                              hidden
                            />

                            <Flash
                              flash={isFlashing}
                              onAnimationEnd={() => setIsFlashing(false)}
                            />

                            <div className="transLogo">
                              <img src="/assets/img/HARPIC_LOGO.png" alt="" />
                            </div>

                            {loading && (
                              <div id="hloader-container2">
                                <img
                                  src="/assets/img/Hourglass01.gif"
                                  alt="Processing...."
                                />
                              </div>
                            )}

                            <div
                              id="container-circles"
                              onClick={handleCapture}
                              style={{
                                marginBottom: "40px",
                                pointerEvents: loading ? "none" : "",
                              }}
                              hidden={loading || resDelay || !ready}
                            >
                              <div id="outer-circle">
                                <div id="inner-circle" className=""></div>
                              </div>
                            </div>

                            {mediaStream && !productionEnabled && (
                              <Button
                                onClick={
                                  !isRecording ? startRecording : stopRecording
                                }
                                style={{
                                  display: productionEnabled ? "none" : "",
                                }}
                                hidden
                              >
                                {!isRecording
                                  ? "Start Recording"
                                  : "Stop Recording"}
                              </Button>
                            )}

                            {mediaStream && !productionEnabled && (
                              <div
                                style={{
                                  fontFamily: "Helvetica Neue",
                                  fontSize: "20px",
                                  height: "77px",
                                  display: productionEnabled ? "none !important" : "",
                                }}
                                hidden={loading || resDelay}
                                className="logoTxt"
                              >
                                <p style={{
                                  background: "rgb(0, 0, 0, 0.4)",
                                }}>
                                  Please align the harpic logo
                                  <br />
                                  with the bottle and click below
                                </p>{" "}
                              </div>
                            )}
                          </Container>
                        )}
                      </Measure>

                      <div className="ProcessingImage1 text-center" hidden>
                        <div
                          className="blackDiv1 c-subscribe-box1 u-align-center"
                          id="parentDiv"
                          hidden
                        >
                          <canvas id="canvas"></canvas>
                          <img src="/assets/img/Hour_Glass.gif" alt="" hidden />
                          <img
                            src="/assets/img/HARPIC_LOGO.png"
                            alt=""
                            hidden
                          />
                        </div>
                      </div>
                    </section>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div hidden={!scan}>
        {scanV2 ? (
          <div className="browser-container">
            <div className="dev-wrapper">
              <div className="webcam-wrapper ">
                <div className="button-container mt-2">
                  <br />
                  <div className="container">
                    <div className="section-webcam" style={Ticker}>
                      <div></div>
                      <div style={{ borderRadius: "35px", overflow: "hidden" }}>
                        <img
                          src={newImage}
                          style={{
                            height: "39vh",
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <div style={Ticker}>
                        <img
                          src={greenTick}
                          style={{ marginTop: "-42px", width: "85px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={Ticker}>
                  <h4 className="main-text">
                    <b>Congratulations!</b>
                  </h4>
                  {/* <h2 style={{ color: "red", marginTop: '12px', fontWeight: '500' }}><b>Photo Quality Bad!</b></h2> */}
                  <div style={Ticker}>
                    <div style={{ fontSize: "24px" }}>
                      <i>
                        <div className="para-text">
                          <p className="text-center">
                            You are now part of the Harpic family
                            <br /> of more than 10 crore houses.
                          </p>
                        </div>
                      </i>
                    </div>
                  </div>
                </div>

                <div className="scanProduct1" style={{ margin: "0px 10px" }}>
                  <p className="text-center" to="#" onClick={handleClick}>
                    Scan Another
                    <img src="./assets/img/scan-icon.png" alt="" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : scanV21 ? (
          <div className="browser-container">
            <div className="dev-wrapper">
              <div className="webcam-wrapper ">
                <div className="button-container mt-2">
                  <br />
                  <div className="container">
                    <div className="section-webcam" style={Ticker}>
                      <div></div>
                      <div style={{ borderRadius: "35px", overflow: "hidden" }}>
                        <img
                          src={newImage}
                          style={{
                            height: "39vh",
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <div style={Ticker}>
                        {/* <img
                          src={greenTick}
                          style={{ marginTop: "-42px", width: "85px" }}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={Ticker}>
                  <h4 className="main-text" style={{ fontSize:"22px",marginTop:"22px" }}>
                    <b>Congratulations!</b>
                  </h4>
                  <div style={Ticker}>
                    <div style={{ fontSize: "24px" }}>
                      <i>
                        <div className="para-text">
                          <p className="text-center">
                            You are now part of the Harpic family
                            <br /> of more than 10 crore houses.
                          </p>
                        </div>
                      </i>
                    </div>
                  </div>
                </div>
                <div className="scanProduct1" style={{ margin: "0px 10px" }}>
                  <p className="text-center" to="#" onClick={handleClick}>
                    Scan Another
                    <img src="./assets/img/scan-icon.png" alt="" />
                  </p>
                </div>
              </div>
            </div>
          </div>

        ) : scanV3 ? (
          // This section will be displayed when backend Model identifies as No_Product (this include back side of the pack)
          <div className="browser-container">
            <div className="dev-wrapper">
              <div className="webcam-wrapper ">
                <div className="button-container mt-2">
                  <br />
                  <div className="container">
                    <div className="section-webcam" style={Ticker}>
                      <div></div>
                      <div style={{ borderRadius: "35px", overflow: "hidden" }}>
                        <img
                          src={newImage}
                          style={{
                            height: "39vh",
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <div style={Ticker}>
                        <img
                          src={redTick}
                          style={{ marginTop: "-42px", width: "85px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={Ticker}>
                  <h2
                    style={{
                      color: "red",
                      marginTop: "12px",
                      marginBottom: "0px",
                      fontWeight: "500",
                    }}
                  >
                    <b>{msgData.message}</b>
                  </h2>
                  <div style={Ticker}>
                    <div  style={{ fontSize: "22px", marginBottom: "17px", fontStyle: "italic", marginTop: "3px" }}>
                        {/* <h4>Please scan again</h4> */}
                        <h4 style={{ marginBottom:"0px"}}>Please capture another image</h4>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    margin: "0px 10px",
                  }}
                  className="scanProduct1"
                >
                  <p className="text-center" to="#" onClick={handleClick}>
                    RESCAN THE PRODUCT
                    <img src="./assets/img/scan-icon.png" alt="" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : scanV4 ? (
          // Fake response page,
          // This section will be displayed when dec_stat is 3 from backend.
          <div className="browser-container">
            <div className="dev-wrapper">
              <div className="webcam-wrapper ">
                <div className="button-container mt-2">
                  <br />
                  <div className="container">
                    <div className="section-webcam" style={Ticker}>
                      <div></div>
                      <div style={{ borderRadius: "35px", overflow: "hidden" }}>
                        <img
                          src={newImage}
                          style={{
                            height: "39vh",
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <div style={Ticker}>
                        <img
                          src={redTick}
                          style={{ marginTop: "-42px", width: "85px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={Ticker}>
                  <h2
                    style={{
                      color: "red",
                      margin: "15px auto 5px",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                      <b>
                        <p style={{textAlign:"center",margin:"7px 0px"}}>Please reach out to :</p>
                        <p style={{textAlign:"center",margin:"7px 0px"}}>Toll Free: 18001027245, </p>
                        <p style={{textAlign:"center",margin:"7px 0px"}}>Email: Consumercare_IN@reckitt.com </p>
                        <p style={{textAlign:"center",margin:"7px 0px",marginBottom:"10px"}}>for more information</p>
                      </b>
                  </h2>
                  <div style={Ticker}>
                    <div style={{ fontSize: "24px" }}>
                      <i>
                      </i>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    margin: "0px 10px",
                  }}
                  className="scanProduct1"
                >
                  <p className="text-center" to="#" onClick={handleClick}>
                    RESCAN THE PRODUCT
                    <img src="./assets/img/scan-icon.png" alt="" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : checkPage ? (
          // Front end rejected Image response handling
          <div className="browser-container">
            <div className="dev-wrapper">
              <div className="webcam-wrapper ">
                <div className="button-container mt-2">
                  <br />
                  <div className="container ">
                    <div className="section-webcam" style={Ticker}>
                      { decodeImage ? (
                        <></>
                      ) : (
                        <div style={{ overflow: "hidden" }}>
                          <img
                            src={newImage}
                            style={{
                              borderRadius: "35px",
                              height: "38vh",
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      )}
                      <div style={Ticker}>
                        { decodeImage ? (
                          <></>
                        ) : (
                          <img
                            src={redTick}
                            style={{ marginTop: "-42px", width: "85px" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="browser-container">
                  <div className="dev-wrapper">
                    <div className="webcam-wrapper ">
                      <div className="button-container mt-2">
                        <div className="container">
                          <div className="section-webcam" style={Ticker}>
                            <div style={{ position: "relative" }}>
                              { decodeImage ? (
                                // loader image after ML Success from Frontend
                                <div>
                                  <div id="hloader-container">
                                    <img
                                      src="/assets/img/Hourglass01.gif"
                                      alt="Processing...."
                                    />
                                  </div>
                                </div>
                              ) : (modelIndex == 2 || modelIndex == 3) ? (
                                <h4
                                  className="main-text"
                                  style={{ color: "red", marginTop: "15px", marginBottom:"0px", fontSize:"23px" }}
                                >
                                  <b>Product not supported!</b>
                                </h4>
                              ) : modelIndex == 0 ?(
                                  <h4 style={{ color: "red", margin:"17px 0px 0px", fontSize: "20px", textAlign: "center" }}><b>Take the picture of the front of the pack</b></h4>
                              ) : (
                                  <h4
                                    className="main-text"
                                    style={{ color: "red", marginTop: "10px", marginBottom:"0px", fontSize:"23px" }}
                                  >
                                    <b>Photo quality not good.</b>
                                  </h4>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div style={Ticker}>
                    <div className="para-text" style={{ fontSize: "22px", marginBottom: "17px", fontStyle: "italic", marginTop: "3px" }}>
                      {modelIndex == 3 ? (
                        <div className="text-center">
                          <h4 style={{ marginBottom:"0px"}}>Unable to detect a harpic bottle.</h4>
                          {/* <h4 style={{ marginBottom:"0px"}}>Please try to focus again</h4> */}
                        </div>
                      ) : modelIndex == 0 ? (
                        <i className="text-center"></i>
                      ) : modelIndex == 4 ? (
                        <div className="text-center">
                          {/* <h4 style={{ marginBottom:"0px"}}>Photo blurred,</h4>
                          <h4 style={{ marginBottom:"0px"}}>please hold product steady</h4> */}
                          <h4 style={{ marginBottom:"0px"}}>Please capture another image</h4>
                        </div>
                      ) : (
                        // <i className="text-center">Please scan again</i>
                          <h4 style={{ marginBottom:"0px"}}>Please capture another image</h4>
                      )}
                    </div>
                  </div>
                </div>
                { decodeImage ? (
                  <div></div>
                ) : (
                  <div
                    className="scanProduct1 "
                    style={{ position: "relative", margin: "0px 10px" }}
                  >
                    <p className="text-center" to="#" onClick={handleClick}>
                      RESCAN THE PRODUCT
                      <img src="./assets/img/scan-icon.png" alt="" />
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>

    </div>
  );

};

export default MainPart;

const Ticker = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxHeight: "50vh",
};