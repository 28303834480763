import { useCookies } from "react-cookie";
import React, { useState, useEffect, Suspense, lazy } from "react";

import FirstSection from "./components/FirstSection";

import HeadV2 from "./components/HeadV2";
import Footer from "./components/Footer";
import MainPart from "./components/MainPart";

const delay = (time) => (promiseResult) =>
  new Promise((resolve) => setTimeout(() => resolve(promiseResult), time));

// lazy load with delay timings.
const InnerContent = React.lazy(() =>
  import("./components/InnerContent").then(delay(3000))
);
const MainPart2 = React.lazy(() =>
  import("./components/MainPart").then(delay(3000))
);

function App() {
  const [cookies, setCookie] = useCookies();
  // state repsonsible for showimg instruction page upto 2 Sec
  let [startPage, setStartPage] = useState(true);
  let scanAgain = cookies.scanAgain;
  if (scanAgain==1) {
    
    startPage =false;
  }
  useEffect(() => {});

  setTimeout(() => {
    setStartPage(false);
  
  }, 3000);

  const DivContainer = {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  };
  const spacing = { padding: "60px" };
  const sectionOne = { display: "flex", justifyContent: "center" };
  const sectionTwo = { display: "flex", justifyContent: "center",display:'block',marginTop:'4vh' };
  return (
    // main div wrapping all App Components
    <div style={DivContainer}>
      {/* Header Component */}
      <div>
        <HeadV2 />
      </div>

      <div style={spacing}></div>

      <div>
        <div style={sectionOne} hidden={!startPage}>
          <FirstSection />
        </div>
        {/* component will only available when camera is needed */}
        {
          (scanAgain==1)? <MainPart/>: <div style={sectionOne}>
          <Suspense fallback={<div></div>}>
            <MainPart2 />
          </Suspense>
        </div>

        }
        

        {/* Resource, Link, image and Content */}
        <div style={sectionOne}>
          <Suspense fallback={<div></div>}>
            <InnerContent />
          </Suspense>
        </div>
      </div>
      <div>
      {/* and Footer component  */}
        <Footer />
      </div>
    </div>
  );
}

export default App;