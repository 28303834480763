import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import image4 from "../public/assets/img/HarpicBottle.png";
import HarpicPic from "../public/assets/img/scan-icon.png";
import intro from '../public/assets/img/20Intro.gif'
const FirstSection = () => {
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [status, setStatus] = useState(null);
  const [cookies, setCookie] = useCookies([
    "deviceUsername",
    "deviceUserId",
    "latitude",
    "longitude",
    "clientIP",
  ]);

  useEffect(() => {
    // Call to generate username and Userid
    generateUserNameNId();
    getLocation();
    console.log(status);
    console.log("Latitude", lat);
    console.log("Longitude", lng);
    getIP();
  }, []);

  const getIP = async () => {
    var checkIp = cookies.clientIP;

    if (checkIp === "" || typeof checkIp === "undefined") {
      await fetch("https://api.ipify.org/?format=json")
        .then((response) => response.json())
        .then((responseData) => {
          //console.log(responseData);
          let ipAddress = responseData.ip;
          setCookie("clientIP", ipAddress, {
            path: "/",
            expires: 0,
            secure: true,
            sameSite: "lax",
          });
        })
        .catch((error) => console.warn(error));
    }
    console.log("Clint IP ==", cookies.clientIP);
  };

  const generateUserNameNId = () => {
    let deviceUsername = cookies.deviceUsername;
    let deviceUserId = cookies.deviceUserId;

    let userNameCode = "";
    let userID = "";
    console.log("Old Username code= ", deviceUsername);
    console.log("Old UserID=", deviceUserId);

    if (
      (deviceUsername === null || typeof deviceUsername === "undefined") &&
      (deviceUserId === null || typeof deviceUserId === "undefined")
    ) {
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var possibleUserId = "0123456789";

      for (var i = 0; i < 16; i++) {
        userNameCode += possible.charAt(
          Math.floor(Math.random() * possible.length)
        );
      }

      for (var j = 0; j < possibleUserId.length; j++) {
        userID += possibleUserId.charAt(
          Math.floor(Math.random() * possibleUserId.length)
        );
      }

      setCookie("deviceUsername", userNameCode, {
        path: "/",
        expires: 0,
        secure: true,
        sameSite: "lax",
      });
      setCookie("deviceUserId", userID, {
        path: "/",
        expires: 0,
        secure: true,
        sameSite: "lax",
      });
    }

    console.log("New Username code= ", userNameCode);
    console.log("New UserID=", userID);
  };
// fetching location details 
  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus("Location is available");
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          setCookie("latitude", position.coords.latitude, {
            path: "/",
            expires: 0,
            secure: true,
            sameSite: "lax",
          });
          setCookie("longitude", position.coords.longitude, {
            path: "/",
            expires: 0,
            secure: true,
            sameSite: "lax",
          });
        },
        () => {
          setStatus("Unable to retrieve your location");
          setLat(0);
          setLng(0);
          setCookie("latitude", 0, {
            path: "/",
            expires: 0,
            secure: true,
            sameSite: "lax",
          });
          setCookie("longitude", 0, {
            path: "/",
            expires: 0,
            secure: true,
            sameSite: "lax",
          });
        }
      );
    }
  };
  const homeContent = {
    display: "flex",

    color: "#083668",
    marginTop: "40px",
    marginLeft: "30px",
    textTransform: "uppercase",

    paddingLeft: " 0.1em",
    fontFamily: "'Poppins',bolder",
    fontSize: "2vw",
    width: "264px",
    height: "90px",
    MaxHeight: "121px",
  };
  const card = {
    display: "grid",
    marginTop: "30mm",

    width: "380px",
    height: "330px",
    paddingBottom: "10px",
    backgroundColor: "#F1F1F1",
    marginBottom: "50px",
    borderRadius: "14px 14px 14px 14px",
  };
  const paragraph ={
    fontWeight: "600",
    marginRight: "30px",
    marginBottom: "100px",
    justifyItems: "center",
    fontFamily: "Poppins",
    fontFamily:'Helvetica Neue',
  }
  const introDiv = {paddingTop: "110px", width: "355px", height: "450px", marginBottom: "50px", }

  const imageContainer={ width: "60px", height: "125px", marginTop: "-20px" }
  const mainText = { fontWeight: "600", justifyItems: "center",fontFamily:'Helvetica Neue' }
  return (
    // <div style={card}>
    //   <div style={homeContent}>
    //     <h1 style={mainText}>
    //       EMPOWERING <br /> YOU WITH <br /> INFORMATION
    //     </h1>
    //     <div>
    //       <img
    //         src={image4}
    //         style={imageContainer}
    //         alt=""
    //       />
    //     </div>
    //   </div>
    //   <div className="infoBar text-center">
    //     <h6 style={paragraph}>
    //       Get authentic product information
    //     </h6>
    //   </div>
    // </div>
    <div>
<img
          src={intro}
          style={introDiv}
          alt="loading..."
          loop='infinite'
        />
    </div>
  );
};

export default FirstSection;
