export default function Footer() {



    const btn = [
        {
            id: 1,
            content: "Privacy Policy",
            link: "https://www.harpic.co.in/privacy-policy/"
        },
        {
            id:"5",
            content: "|",
        },
        {
            id: 2,
            content: "Terms And Conditions",
            link: "https://www.harpic.co.in/terms-and-conditions/"
        },
        {
            id:"5",
            content: "|",
        },
        {
            id: 3,
            content: "Other RB Brands",
            link: "https://www.reckitt.com/"
        },
        {
            id:"5",
            content: "|",
        },
        {
            id: 4,
            content: "Cookie Policy",
            
            link: "https://www.harpic.co.in/cookie-policy/"
        },

    ]
    const container = {
        position: "sticky",
        height: "50px",
        backgroundColor: "rgba(230, 50, 39, 1)",
        

        // zIndex:"100",

    }
    const textContainer = {
        fontFamily:'Helvetica Neue',
        display: "flex",
        margin:"2px 15px 0px 15px",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        textAlign: "center",
        color: "rgba(255, 255, 255, 1)",
        fontSize: "10px",
        fontFamily: "He",
        // lineHeight:"34px"
    }

    return (
        <div style={container}>
            <div style={textContainer}>
                {
                    btn.map((b) => (
                        <div key={b.id}>
                            
                            <div className="text-center"><p onClick={() => window.open(b.link, "_blank")}>{b.content}{b.main}</p></div>

                        </div>

                    ))
                }


            </div>
            <div><p className="text-center" style={{fontFamily:'Helvetica Neue',color:"#FFFFFF", fontSize: "12px" }}>Powered by Enncrypto</p></div>

        </div>
    )
}