import '../public/assets/css/style.css'
export default function HeadV2() {
  const headerStyle = {
    position: "fixed",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    marginBottom: "30px",
    width: "100%",
    zIndex:"100"
    
  };
  const leftLine = {
    backgroundColor: "#001489",
    marginRight: "90%",
    float: "left",
    width: "70%",
    height: "7px",
    paddingRight:"-120px",
    // borderBottom:"6px solid green",
    // borderRight:"6px solid green",
    position: "absolute",
    // borderRadius: "0px 0px 70px 0px",
    // backgroundClip: "inherit",
    // zIndex:"-1",
  
  // transform:"translate(-0.0732233047em, calc(0.1767766953em - .5px)) rotate(45deg)",
  // // transformOrigin: "top left",
  // content: " ",
  // display: "block",
  // transformOrigin: "bottom left"

    
  };
  const rightLine = {
    backgroundColor: "#E63227",
    float: "right",
    width: "35%",
    height: "7px",
    // borderTop:"6px solid transparent",
    left: "30%",
  };
  const topLogo = {
    maxWidth: " 100px",
    height: "120px",
    // paddingLeft: "10px",
    marginBottom: "10px",
  };
 
  const headText = {
    display: 'flex',
  flexDirection:'column',
   paddingRight:'12px',
    textAlign: "left",
    marginRight:'52px',
    margin: "25px ",
    marginLeft:'15px'
    
  };
  const mainText = {
    // textShadow: "0px 1px, 1px 0px, 1px 1px",
  //  marginLeft:'-26px',
   marginRight:'-36px',
    fontSize: "25px",
    fontFamily:'Helvetica Neue',
    fontWeight:"bold",
    color: "#E2261D",
    width:"auto"
    
  };
  const paraText = {
 
    position: "absolute",
    fontFamily:'Helvetica Neue',
    color: "#083668",
    fontSize:"26px",
    fontWeight:"bold",
    textAlign:"left"
  };
  return (
    <div style={headerStyle}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <img style={topLogo} src="/assets/img/harpic.png" alt="" />
        </div>
        <div style={headText}>
          <h1 className='harpicPlus'>HARPIC POWER PLUS </h1>
          <div>
            <h2  className='newAnd'>KILLS 99.9% GERMS</h2>
          </div>
        </div>
      </div>
      <div className='leftDiv' style={leftLine}></div>
     
      <div style={rightLine}></div>
    </div>
  );
}
